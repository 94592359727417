// Generated by Framer (101bcc7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-tq1pc"

const variantClassNames = {FDXOC1xEl: "framer-v-q254dx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transformTemplate1 = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, optionTitle, width, ...props}) => { return {...props, Tm2p46NaA: optionTitle ?? props.Tm2p46NaA ?? "ΑΡΧΙΚΗ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;optionTitle?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Tm2p46NaA, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "FDXOC1xEl", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-q254dx", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"FDXOC1xEl"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1pvbmEgUHJvIExpZ2h0", "--framer-font-family": "\"Zona Pro Light\", \"Zona Pro Light Placeholder\", sans-serif", "--framer-font-size": "13px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>ΑΡΧΙΚΗ</motion.p></React.Fragment>} className={"framer-h9yuy1"} fonts={["CUSTOM;Zona Pro Light"]} layoutDependency={layoutDependency} layoutId={"axYtcHSEf"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={Tm2p46NaA} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-tq1pc.framer-c5f97x, .framer-tq1pc .framer-c5f97x { display: block; }", ".framer-tq1pc.framer-q254dx { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; min-height: 72px; min-width: 70px; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-tq1pc .framer-h9yuy1 { flex: none; height: auto; left: 49%; position: absolute; top: 0px; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-tq1pc.framer-q254dx { gap: 0px; } .framer-tq1pc.framer-q254dx > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-tq1pc.framer-q254dx > :first-child { margin-top: 0px; } .framer-tq1pc.framer-q254dx > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 72
 * @framerIntrinsicWidth 70
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Tm2p46NaA":"optionTitle"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerDFjc9yTqZ: React.ComponentType<Props> = withCSS(Component, css, "framer-tq1pc") as typeof Component;
export default FramerDFjc9yTqZ;

FramerDFjc9yTqZ.displayName = "optionValue";

FramerDFjc9yTqZ.defaultProps = {height: 72, width: 70};

addPropertyControls(FramerDFjc9yTqZ, {Tm2p46NaA: {defaultValue: "ΑΡΧΙΚΗ", description: "Here you will enter the Value Name :)", displayTextArea: false, title: "optionTitle", type: ControlType.String}} as any)

addFonts(FramerDFjc9yTqZ, [{explicitInter: true, fonts: [{family: "Zona Pro Light", source: "custom", url: "https://framerusercontent.com/assets/LN2M82vvHgKujXBQYgRgAhc2CU.woff2"}]}], {supportsExplicitInterCodegen: true})